<template>
    <div>
        <router-view class="dataService-index-view"></router-view>
    </div>
</template>
<script>
export default {
    name: "equipment_crack",
    data() {
        return {

        }
    }
}
</script>